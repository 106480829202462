import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {NoSanitizePipe} from './no-sanitize.pipe';

@NgModule({
  imports: [
    RouterModule
  ],
  declarations: [
    NoSanitizePipe,
  ],
  exports: [
    NoSanitizePipe,
  ]
})
export class PipesModule {
}
