export class User {

  firstName: string;
  lastName: string;
  role: string;
  phoneNumber: string;
  email: string;



  constructor(firstName: string, lastName: string, role: string, phoneNumber: string,email: string
              ) {
    
    this.firstName = firstName;
    this.lastName = lastName;
    this.role = role;
    this.phoneNumber = phoneNumber;
    this.email = email;

  }

}
