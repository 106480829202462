<div class="bg-img">
  <div class="container">
    <div class="empty-space"></div>
    <div class="topnav">
      <a href='#' data-item='ACCUEIL' routerLinkActive=" active" routerLink="acceuil">ACCUEIL</a>
      <a href='#' data-item='ABOUT' routerLinkActive="active" routerLink="a-propos">NOTRE SERVICE</a>
      <a href='#' data-item='ABONNEMENT' routerLinkActive="active" routerLink="abonnement">ABONNEMENTS</a>
      <a href='#' data-item='TUTO' routerLinkActive="active" routerLink="tuto">TUTOS</a>
      <a href='#' data-item='FAQ' routerLinkActive="active" routerLink="faq">FAQ</a>
      <a href='#' *ngIf="isAuth" data-item='Panier' routerLinkActive="active"
        routerLink="panier">Panier({{itemsLength}})</a>
      <a href='#' *ngIf="isAuth" data-item='Orders' routerLinkActive="active" routerLink="orders">Commandes</a>
      <a href='#' data-item='login' routerLinkActive="active" routerLink="login">{{ isAuth ? 'Bienvenue':'Login'}}</a>
    </div>
    <div class="empty-space"></div>
  </div>
</div>