import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import * as firebase from 'firebase';
import { Tuto } from '../models/tuto';

@Injectable()
export class TutoService {

  private tutos: Tuto[] = [];
  tutoSubject = new Subject<any[]>();

  constructor() {
    this.getTutos();
  }
  emitTutos() {
    this.tutoSubject.next(this.tutos);
  }

  getTutos() {
    firebase.database().ref('/tutos').on('value', (data: firebase.database.DataSnapshot) => {
      this.tutos = data.val() ? data.val() : [];
      this.emitTutos();
    })
  }

  createNewTuto(newTuto: Tuto) {
    this.tutos.push(newTuto);
    this.saveTutos();
    this.emitTutos();
  }

  removeTuto(tuto: Tuto) {
    const tutoIndexToRemove = this.tutos.findIndex(
      (tutoEl) => {
        if (tutoEl === tuto) {
          return true;
        }
      }
    );
    this.tutos.splice(tutoIndexToRemove, 1);
    this.saveTutos();
    this.emitTutos();
  }

  saveTutos() {
    firebase.database().ref('/tutos').set(this.tutos);
  }


}
