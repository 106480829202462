<div class="alert-container">
  <ngb-alert *ngIf="message" type="{{ type }}" (close)="message = null">
    <div class="alert-main">
      <div *ngIf="type === 'danger'"><img src="../../assets/icons/alert_big.png"></div>
      <div *ngIf="type === 'warning'"><img src="../../assets/icons/warning_big.png"></div>
      <div *ngIf="type === 'success'"><img src="../../assets/icons/success_big.png"></div>
      <div [innerHTML]="(message | translate:param) | noSanitize" class="alert-text"></div>
    </div>
  </ngb-alert>
</div>


