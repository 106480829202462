import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faArrowUp , faGlobeEurope } from '@fortawesome/free-solid-svg-icons';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscribable, Subscription } from 'rxjs';
import { Tuto } from '../models/tuto';
import { TutoService } from '../services/tuto.service';
import { AuthService } from '../services/auth.service';
import * as firebase from 'firebase';
import * as QuillNamespace from 'quill';
let Quill: any = QuillNamespace;
import ImageResize from 'quill-image-resize-module';
Quill.register('modules/imageResize', ImageResize);



@Component({
  selector: 'app-tuto',
  templateUrl: './tuto.component.html',
  styleUrls: ['./tuto.component.scss']
})
export class TutoComponent implements OnInit {
  isAuth: boolean = false;
  faArrowUp = faArrowUp;
  editorForm: FormGroup;

  editorStyle = {
    width: '100%',
    height: '300px',
    backgroundColor: '#ffffff',
    
  };

  editorModules = {
    toolbar: {
      container: [
        [{ 'font': [] }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'header': 1 }, { 'header': 2 }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'align': [] }],
        ['link', 'image']
      ]
    },
    imageResize: true
  };

  tutos: Tuto[];
  tutosSubscritption: Subscription;
  constructor(private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router, private tutoService: TutoService
  ) {
  }

  ngOnInit() {
    firebase.auth().onAuthStateChanged(
      (user) => {
        if (user) {
          this.isAuth = true;
        } else {
          this.isAuth = false;
        }
      }
    )
    this.tutosSubscritption = this.tutoService.tutoSubject.subscribe(
      (tutos: Tuto[]) => {
        this.tutos = tutos;
      }
    );
    this.tutoService.getTutos();
    this.tutoService.emitTutos();
    this.editorForm = new FormGroup({
      'editorTemplate': new FormControl('edit a tutoriel')
    });
    document.querySelector('img').setAttribute('width', '100px');
  }

  onSubmit() {
    console.log(this.editorForm.value);
    const newTuto = new Tuto('titre du tuto', this.editorForm.value);
    this.tutoService.createNewTuto(newTuto);
    this.editorForm = new FormGroup({
      'editorTemplate': new FormControl('edit a tutoriel')
    })
  }

  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  deleteTuto(tuto: Tuto) {
    this.tutoService.removeTuto(tuto);
  }


  current() {
    if (this.router.url.endsWith('smartTV')) {
      return 'smartTV';
    } else if (this.router.url.endsWith('android')) {
      return 'android';
    } else if (this.router.url.endsWith('iphone')) {
      return 'iphone';
    } else if (this.router.url.endsWith('mag')) {
      return 'mag';
    } else if (this.router.url.endsWith('vpn')) {
      return 'vpn';
    } else if (this.router.url.endsWith('faq')) {
      return 'faq';
    }

  }

}
