<div *ngIf="!isAuth" class="container" id="container">
  <div class="form-container sign-up-container">
    <form [formGroup]="signupForm" (ngSubmit)="onSubmit()">
      <h1>Create Account</h1>
      <span>or use your email for registration</span>
      <input type="text" placeholder="firstName" formControlName="firstName" id="firstName" />
      <input type="text" placeholder="lastName" formControlName="lastName" id="lastName" />
      <input type="tel" placeholder="phoneNumber" formControlName="phoneNumber" id="phoneNumber" />
      <input type="email" placeholder="Email" formControlName="email" id="email" />
      <input type="password" placeholder="Password" formControlName="password" id="password" />
      <button type="submit"[disabled]="signupForm.invalid" >Sign Up</button>
    </form>
    <p class="text-danger"> {{errorMessage}}</p>
  </div>
  <div class="form-container sign-in-container">
    <form [formGroup]="signinForm" (ngSubmit)="login()">
      <h1>Sign in</h1>
      <span>or use your account</span>
      <input class="form-control" id="email" name="email" placeholder="Identifiant" formControlName="email" type="text">
      <input class="form-control" id="password" name="password" placeholder="Mot de passe" formControlName="password"
        type="password"><a href="#">Forgot your password?</a>
      <button type="submit" [disabled]="signinForm.invalid">Sign In</button>
    </form>
    <p class="text-danger"> {{errorMessage}}</p>
  </div>
  <div class="overlay-container">
    <div class="overlay">
      <div class="overlay-panel overlay-left">
        <h1>Welcome Back!</h1>
        <p>To keep connected with us please login with your personal info</p>
        <button (click)="addclass()" class="ghost" id="signIn">Sign In</button>
      </div>
      <div class="overlay-panel overlay-right">
        <h1>Hello, Friend!</h1>
        <p>Enter your personal details and start journey with us</p>
        <button (click)="removeClass()" class="ghost" id="signUp">Sign Up</button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isAuth">
  <form [formGroup]="editUserForm" (ngSubmit)="onEdit()">
    <h1>Account Modification </h1>
    <input type="text" placeholder="firstName" formControlName="firstName" id="firstName" />
    <input type="text" placeholder="lastName" formControlName="lastName" id="lastName" />
    <input type="tel" placeholder="phoneNumber" formControlName="phoneNumber" id="phoneNumber" />
    <input type="email" placeholder="Email" formControlName="email" id="email" />
    <input type="password" placeholder="Password" formControlName="password" id="password" />
    <button type="submit">Edit</button>
  </form>
  <div style="text-align: center;">
    <button (click)="logout()">Déconnexion</button>
  </div>

</div>