<div class="container">
    <section class="jumbotron text-center">
        <div class="container">
            <h1 class="jumbotron-heading">Votre panier:</h1>
        </div>
    </section>

    <div class="container mb-4">
        <div class="row">
            <div class="col-12">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Formule </th>
                                <th scope="col">Type</th>
                                <th scope="col" class="text-center">Quantité</th>
                                <th scope="col" class="text-right">Prix</th>
                                <th> </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of items">
                                <td>Formule {{item.name}} 12 mois</td>
                                <td>{{item.name}}</td>
                                <td>{{item.count}}</td>
                                <td class="text-right">{{item.prize}}</td>
                                <td class="text-right"><button class="btn btn-sm btn-success"
                                        (click)="addOneFormule(item)"><i class="fa fa-plus"></i> </button> </td>
                                <td class="text-right"><button class="btn btn-sm btn-danger"
                                        (click)="removeOneFormule(item)" [disabled]="item.count === 0"><i
                                            class="fa fa-trash"></i> </button> </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td><strong>Total</strong></td>
                                <td class="text-right"><strong> {{finalAmount}} €</strong></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col mb-2 paypal-button">
                <div class="row">
                    <div class="col-sm-12  col-md-6" *ngIf="paidFor">
                        <h1>Merci de votre achat</h1>>
                    </div>
                    <div class="col-sm-12  col-md-6">
                        <div #paypal></div>
                    </div>
                    <div class="col-sm-12  col-md-6">
                        <div #paypal>
                            <button class="btn btn-success text-center"
                            href="#" (click)="sendCommande()">Envoyer la commande</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>