import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';


@Injectable()
export class WatchRouterService {
  private componentName = new BehaviorSubject<string>('base');

  constructor() {
  }

  get isComponent(): Observable<string> {
    return this.componentName.asObservable();
  }

  getCurrentComponentName(component): void {
    this.componentName.next(component);
  }
}
