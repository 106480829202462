import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {AlertService} from '../services/alert.service';
import {WatchRouterService} from '../services/watch-router.service';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {
  public message: string;
  public type: string;
  public param: object;
  public component: Observable<string>;

  constructor(private alertService: AlertService, private watchRouter: WatchRouterService) {
  }

  ngOnInit() {
    this.component = this.watchRouter.isComponent;
    // Get alert content form alert service
    this.alertService.getAlert.subscribe((message) => {
      this.message = message[0];
      this.type = message[1];
      if (message[2]) {
        this.param = {value: message[2]};
      }
    });
    // Close the alert after 10 seconds
    this.alertService.getAlert.pipe(debounceTime(10000)).subscribe(() => this.message = null);
  }
}
