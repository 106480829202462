import { Component, OnInit } from '@angular/core';
import { Item } from '../models/item';
import { CartService } from '../services/cart.service';
@Component({
  selector: 'app-abonnement',
  templateUrl: './abonnement.component.html',
  styleUrls: ['./abonnement.component.scss']
})
export class AbonnementComponent implements OnInit {

  constructor(private cartService: CartService) { }

  ngOnInit(): void {
  }

  addToCart(name: string) {
    const id = name === 'standard' ? 1 : name === 'sport' ? 2 : name === 'premium' ? 3 : 1;
    const prix = name === 'standard' ? 50 : name === 'sport' ? 70 : name === 'premium' ? 90 : 1;
    const item = new Item(id, name, prix, 0);
    document.location.href = 'https://t.me/NORDIPTV59';
    // this.cartService.addToCart(item);
  }
}
