import { Injectable } from '@angular/core';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(private readonly alertService: AlertService) { }

  public handleError(error: Error) {
    console.error(error.message);
    this.alertService.setAlertWithVariable([error.message, 'danger']);
  }
}
