<div class="carousel">
  <app-carousel [slides]="slides"></app-carousel>
</div>
<section id="responsive" class="top-padding">
  <div class="container-fluid">
    <div class="row responsive-pic">
      <div class="col-md-6 col-sm-6 fadeInLeft" data-wow-duration="500ms" data-wow-delay="600ms">
        <img src="../../assets/compatible_iptv.png" alt="fully responsive" class="img-responsive">
      </div>
      <div class="container fadeInRight" data-wow-duration="500ms" data-wow-delay="900ms">
        <div class="row">
          <div class="col-md-6 col-sm-6 r-test">
            <h3 class="magin30">Compatibilité</h3>
            <p id="custom">Vous voulez regarder vos chaines préférées, Sports, Documentaires ou Emissions,
              partout et sur tous vos Appareils <br></p>
            <p class="titre">Nous proposons une solution adaptée a vos besoins.</p>
            <h4>Périphériques supportés</h4>
            <ul class="r-feature">
              <li>Android TV</li>
              <li>Box Android</li>
              <li>Apple TV</li>
              <li>Chromebook</li>
              <li>Smart TV (LG, Samsung)</li>
              <li>MAG-STB</li>
              <li>KODI, VLC</li>
              <li>windows, IOS</li>
              <li>Ipad,Tablette</li>
              <li>Smartphone</li>
            </ul>
            <div class="screens"> <i class="icon-laptop2"></i> <i class="icon-tablet2"></i> <i
                class="icon-icons202"></i> </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br>
</section>

<div class="livraison">
  <p>
    Notre service est accéssible partout dans le monde ,il suffit d'avoir une connexion internet d'au moins 5 Méga pour du SD <br>
    Tous nos appareils sont configurés avec l'abonnement de votre choix. <br>
    Nos Colis sont expédiés par mondial relay.<br>
    Délai de livraison : 48H à 72H avec suivi du colis.<br>
  </p>
</div>