<div id="faq">
    <div class="container">
        <h2 class="section-title" #target>
            FAQ
        </h2>
        <div class="faqForm" *ngIf="isAuth">
            <form [formGroup]="faqForm" (ngSubmit)="isUpdate ? onUpdateFaq():onSubmitFaqForm()">
                <input style="display: none;" type="number" id="id" formControlName="id">
                <div class="form-group">
                    <label for="question">Question</label>
                    <input type="text" id="question" class="form-control" formControlName="question">
                </div>
                <div class="form-group">
                    <label for="reponse">Réponse</label>
                    <textarea id="reponse" class="form-control" formControlName="reponse" rows="4" cols="50"></textarea>
                </div>
                <button type="submit" [ngClass]=" isUpdate ? 'btn btn-primary':' btn btn-success'"
                    [disabled]="faqForm.invalid">
                    {{isUpdate ? 'Editer Ce Faq':'Ajouter ce Nouveau Faq'}} </button>
            </form>
        </div>
        <div class="accordion" id="accordionFAQ">
            <div class="card" *ngFor="let faq of faqs; let i = index">
                <div class="card-header" [id]="'heading'+faq.id">
                    <button class=" collapsed" type="button" [attr.data-toggle]="'collapse'"
                        [attr.data-target]="'#collapse'+faq.id" [attr.aria-expanded]="false"
                        [attr.aria-controls]="'collapse'+faq.id">
                        <span>
                            {{faq.question}}
                        </span>
                    </button>
                </div>

                <div [id]="'collapse'+faq.id" class="collapse" [attr.aria-labelledby]="'heading'+faq.id"
                    data-parent="#accordionFAQ">
                    <div class="card-body">
                        {{faq.reponse}}
                    </div>
                    <div *ngIf="isAuth" class="editionBloc">
                        <div><button type="button" class="btn btn-primary"
                                (click)="onEditFaq(faq,target)">editer</button></div>
                        <div><button type="button" class="btn btn-danger" (click)="onDeleteFaq(faq)">supprimer</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <br>

        <div class="text-right support-text">
            <p>
                Vous avez d'autres questions ?

                <!-- redirect to your contact page -->
                <a href="#">
                    Envoyer un mail à l'adresse qui se trouve en bas
                </a>

            </p>
        </div>
    </div>