import { Subject } from 'rxjs';
import { Faq } from './../models/faq';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase';

@Injectable()
export class FaqService {

  private faqs: Faq[] = [];
  faqSubject = new Subject<any[]>();

  constructor() {
    this.getFaqs();
  }
  emitFaqs() {
    this.faqSubject.next(this.faqs);
  }

  getFaqs() {
    firebase.database().ref('/faqs').on('value', (data: firebase.database.DataSnapshot) => {
      this.faqs = data.val() ? data.val() : [];
      this.emitFaqs();
    });
  }
  updateFaq(faq: Faq) {
    firebase.database().ref('/faqs/' + faq.id).update(faq);
  }

  createNewFaq(newFaq: Faq) {
    this.faqs.push(newFaq);
    this.saveFaqs();
    this.emitFaqs();
  }

  removeFaq(faq: Faq) {
    const faqIndexToRemove = this.faqs.findIndex(
      (faqEl) => {
        if (faqEl === faq) {
          return true;
        }
      }
    );
    this.faqs.splice(faqIndexToRemove, 1);
    this.saveFaqs();
    this.emitFaqs();
  }

  saveFaqs() {
    firebase.database().ref('/faqs').set(this.faqs);
  }



}
