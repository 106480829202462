export class Item {
    id: number;
    name: string;
    prize: number;
    count: number;

    constructor(id: number,name: string, prize: number, count: number ) {
        this.id = id;
        this.name = name;
        this.prize = prize;
        this.count = count;
    }
}