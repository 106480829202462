export class Faq {
  id: number;
  question: string;
  reponse: string;

  constructor(id: number, question: string, reponse: string) {
    this.id = id;
    this.question = question;
    this.reponse = reponse;
  }
}
