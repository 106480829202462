import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import * as firebase from 'firebase';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private router: Router,
    private Location: LocationStrategy,
    private translate: TranslateService
  ) {
    history.pushState(null, null, window.location.href);
    translate.setDefaultLang('fr');

    var firebaseConfig = {
      apiKey: 'AIzaSyCPGvxxREg-SkIr9TuHIrMiE6qXzaXiFhs',
      authDomain: 'offre-iptv.firebaseapp.com',
      databaseURL: 'https://offre-iptv.firebaseio.com',
      projectId: 'offre-iptv',
      storageBucket: 'offre-iptv.appspot.com',
      messagingSenderId: '954285250710',
      appId: '1:954285250710:web:c174ddde21d278e5558ab7',
      measurementId: 'G-XJ3SP0SYKL',
    };
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
  }
}
