<div class="container">
    <div class="container-fluid">
        <div class="row">
            <p> Open for You</p>
            <h1> NORD IPTV </h1>
            <p> Nous sommes un fournisseur d’abonnement IPTV, m3u, Mag et autre, de haute qualité avec plusieurs années
                d’expérience, nos serveurs IPTV disposent d’une playlist de plus de 1500 chaines en fullHD, HD et SD &
                VODS en FullHD,
                HD et 4k (derniers films et séries à jour), la performance de nos serveurs IPTV est une priorité pour
                nous.
            </p>
            <br>
            <h4> Whatsapp: </h4>
            <h4> Email: nordiptv5962@gmail.com</h4>
        </div>
    </div>
</div>