import { User } from '../models/user';
import { Subject } from 'rxjs';
import { Injectable, OnInit } from '@angular/core';
import * as firebase from 'firebase';

@Injectable()
export class UserService {
  private users: User[] = [];
  private user: User;
  usersSubject = new Subject<User[]>();
  connectedUserSubject = new Subject<User>();

  constructor() {}

  emitUsers() {
    this.usersSubject.next(this.users);
  }
  emitUser() {
    this.connectedUserSubject.next(this.user);
  }

  addUser(user: User) {
    this.users.push(user);
    this.saveUsers();
    this.emitUsers();
  }

  saveUsers() {
    firebase.database().ref('/users').set(this.users);
  }
  getUsers(email: string) {
    firebase
      .database()
      .ref('/users')
      .on('value', (data: firebase.database.DataSnapshot) => {
        this.users = data.val() ? data.val() : [];
        this.user = this.users.filter((u) => u.email.match(email))[0];
        this.emitUser();
      });
  }
}
