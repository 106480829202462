import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { Item } from '../models/item';


@Injectable({
  providedIn: 'root'
})
export class CartService {

  private cartArray = [];
  cartArraySubject = new Subject<any[]>();

  constructor() { }

  emitCartArraySubject() {
    this.cartArraySubject.next(this.cartArray.slice());
  }

  addToCart(item: Item) {
    if (this.cartArray.length == 0) {
      this.cartArray.push(item);
    }

    var idArray = [];

    for (var i = 0; i < this.cartArray.length; i++) {
      if (idArray.indexOf(this.cartArray[i].id) == -1) {
        idArray.push(this.cartArray[i].id)
      }
    }

    //item already exists in cartArray, don't push rather update quantity count
    if (idArray.indexOf(item.id) == -1) {
      console.log("item successfully got pushed to idArray", idArray);
      item.count = item.count + 1;
      this.cartArray.push(item);
    } else {
      //this else executes when user clicks on add to cart for the second time and so on, item quantity count key should be updated
      //for that find item in cartArray and push 
      for (var i = 0; i < this.cartArray.length; i++) {
        if (this.cartArray[i].id == item.id) {
          this.cartArray[i].count = this.cartArray[i].count + 1;
        }
      }
    }

    this.emitCartArraySubject();
  }

  deleteItem(id: number) {
    this.cartArray.slice(id);
    this.emitCartArraySubject();
  }

  addOneFormule(item: Item) {
    this.cartArray.forEach(element => {
      if (element.id === item.id) {
        element.count++;
        this.emitCartArraySubject();
      }
    });

  }

  removeOneFormule(item: Item) {
    this.cartArray.forEach(element => {
      if (element.id === item.id) {
        element.count--;
      }
    });
    this.emitCartArraySubject();
  }
}