import { Component, OnChanges, OnDestroy, OnInit, AfterViewChecked, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Item } from '../models/item';
import { CartService } from '../services/cart.service';

declare let paypal;

@Component({
  selector: 'app-panier',
  templateUrl: './panier.component.html',
  styleUrls: ['./panier.component.scss']
})
export class PanierComponent implements OnInit, OnDestroy {
  items: Item[];
  itemsSubscription: Subscription;
  finalAmount: number = 0;
  @ViewChild('paypal', { static: true }) paypalElement: ElementRef;
  paidFor = false;

  constructor(private panierService: CartService, private router: Router) { }

  ngOnInit(): void {
    this.itemsSubscription = this.panierService.cartArraySubject.subscribe(
      (items: Item[]) => {
        this.items = items;
      });
    this.panierService.emitCartArraySubject();
    this.calculTotal();
  }


  private calculTotal() {
    let total = 0;
    this.items.forEach(item => {
      total = total + (item.prize * item.count);
    });
    this.finalAmount = total;
  }

  addOneFormule(item: Item) {
    this.panierService.addOneFormule(item);
    this.calculTotal();
  }

  removeOneFormule(item: Item) {
    this.panierService.removeOneFormule(item);
    this.calculTotal();
  }

  sendCommande() {
    console.log("passage de la commande par mail pour la préparation");
  }
  ngOnDestroy() {
    this.itemsSubscription.unsubscribe();
  }


}
