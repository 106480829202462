import { Component, OnInit } from '@angular/core';
import { faEye, faHourglassHalf, faCloudDownloadAlt,faTv, faCog, faCreditCard } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-a-propos',
  templateUrl: './a-propos.component.html',
  styleUrls: ['./a-propos.component.scss']
})
export class AProposComponent implements OnInit {
  faEye = faEye;
  faHourglassHalf = faHourglassHalf;
  faCloudDownloadAlt = faCloudDownloadAlt;
  faTv = faTv;
  faCog =faCog;
  faCreditCard= faCreditCard;
  constructor() { }

  ngOnInit(): void {
  }

}
