import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private _success = new Subject<Array<string>>();

  get getAlert(): Subject<Array<string>> {
    return this._success;
  }

  setAlert(alert): void {
    this._success.next([alert[0], alert[1]]);
  }

  setAlertWithVariable(alert): void {
    this._success.next([alert[0], alert[1], alert[2]]);
  }

  constructor() {
  }
}
