<div class="container">
    <div class="container-fluid">
        <div class="row">
            <div class="commander">
                <img src="../../assets/banner.jpg">
            </div>
            <section class="section-padding padding" id="pricing">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <h2 class="heading">FORMULES &amp; PRIX</h2>
                        </div>
                        <div class="col-md-12">
                            <div class="pricing pricing_tenzin">
                                <div class="pricing_item active">
                                    <h3 class="pricing_title">FORMULE BASIQUE </h3>
                                    <div class="pricing_price">60 €/AN</div>
                                    <p class="pricing_sentence">SERVEUR STANDARD </p>
                                    <ul class="pricing_list">
                                        <li class="pricing_feature">Serveurs Stables SAUF pour le sport a grandes
                                            affluences </li>
                                        <li class="pricing_feature">Compatible avec tous les périphériques</li>
                                        <li class="pricing_feature">+10000 Films / +1000 Séries </li>
                                        <li class="pricing_feature">Live TV Qualité FHD / HD / SD (Bas débit)
                                        </li>
                                        <li class="pricing_feature">Contenu VOD de qualité 4K / FHD </li>
                                        <li class="pricing_feature">Mise à jour mensuelle</li>
                                    </ul>
                                    <button class="pricing_action text-center"
                                        (click)="addToCart('standard')">Commander</button>
                                </div>
                                <div class="pricing_item active">
                                    <h3 class="pricing_title">FORMULE SPORT </h3>
                                    <div class="pricing_price">80 €/AN</div>
                                    <p class="pricing_sentence">SERVEUR INTERMIDAIRE</p>
                                    <ul class="pricing_list">
                                        <li class="pricing_feature">Serveur Stable pour les événements sportifs avec de
                                            l'affluence</li>
                                        <li class="pricing_feature">Compatible avec tous les périphériques </li>
                                        <li class="pricing_feature">+6000 Films / +1200 Séries (les + récents) </li>
                                        <li class="pricing_feature">Qualité d’image FHD+ / HD / SD (Bas débit)</li>
                                        <li class="pricing_feature">Replay 7 Jours </li>
                                        <li class="pricing_feature">Contenu VOD de qualité 4K / iMAX </li>
                                        <li class="pricing_feature">Mise à jour hébdomadaire</li>
                                    </ul>
                                    <button class="pricing_action text-center"
                                        (click)="addToCart('sport')">Commander</button>

                                </div>
                                <div class="pricing_item active">
                                    <h3 class="pricing_title">FORMULE PREMIUM </h3>
                                    <div class="pricing_price">100 €/AN</div>
                                    <p class="pricing_sentence"> SERVEUR PREMIUM </p>
                                    <ul class="pricing_list">
                                        <li class="pricing_feature">Parmi les 3 TOP Serveurs OTT au monde</li>
                                        <li class="pricing_feature">Service Stable et garantie sans coupure</li>
                                        <li class="pricing_feature">Qualité UHD 4K / FHD / FHD+ </li>
                                        <li class="pricing_feature">HD / SD (bas débit aussi)</li>
                                        <li class="pricing_feature">Compatible avec tous les périphériques</li>
                                        <li class="pricing_feature">15 Jours de Replay HD</li>
                                        <li class="pricing_feature">+30 000 Films, +5500 Séries </li>
                                        <li class="pricing_feature">Contenu VOD de qualité 4K / iMax </li>
                                        <li class="pricing_feature">Le client peut personnaliser ses bouquets TV</li>
                                        <li class="pricing_feature">1 mois d’abonnement OFFERT automatiquement au
                                            parrain</li>
                                        <li class="pricing_feature">Mise à jour quotidienne</li>

                                    </ul>
                                    <button class="pricing_action text-center"
                                        (click)="addToCart('premium')">Commander</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <div class="prk-nous">
                <img src="../../assets/prk-nous.jpg">
            </div>
        </div>

    </div>
</div>