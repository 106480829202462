import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import * as firebase from 'firebase';
import { UserService } from '../services/user-service';
import { User } from '../models/user';
import { CartService } from '../services/cart.service';
import { Item } from '../models/item';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isAuth: boolean;
  user: User;
  itemsLengthSubscription: Subscription;
  itemsLength: number;
  constructor(private authService: AuthService, private userService: UserService, private cartService: CartService) { }

  ngOnInit() {
    firebase.auth().onAuthStateChanged(
      (user) => {
        if (user) {
          this.isAuth = true;
          this.userService.usersSubject.subscribe(
            (users: User[]) => {
              let result = users.filter(u => u.email === user.email);
              this.user = result[0];
            }
          );
        } else {
          this.isAuth = false;
        }
      }
    );
    let totalElement = 0;
    this.itemsLengthSubscription = this.cartService.cartArraySubject.subscribe(
      (items: Item[]) => {
        items.forEach(el => {
          totalElement = + el.count;
        });
        this.itemsLength = totalElement;
      });

    this.cartService.emitCartArraySubject();
  }
}
