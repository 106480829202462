import { OnInit, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { AlertService } from '../services/alert.service';
import { ErrorService } from '../services/error.service';
import * as firebase from 'firebase';
import { User } from '../models/user';
import { UserService } from '../services/user-service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  isAuth: boolean;
  signupForm: FormGroup;
  signinForm: FormGroup;
  editUserForm: FormGroup;
  errorMessage: string;
  lastName: string;
  firstName: string;
  phoneNumber: number;
  email: string;
  password: string;
  userSubscription: Subscription;

  constructor(
    protected authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    protected alertService: AlertService,
    private readonly errorService: ErrorService
  ) {}
  ngOnInit() {
    this.initForm();
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.isAuth = true;
      } else {
        this.isAuth = false;
      }
    });
    this.userSubscription = this.userService.connectedUserSubject.subscribe(
      (user: User) => {
      }
    );
  }

  initForm() {
    this.signupForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: [
        '',
        [Validators.required, Validators.pattern(/[0-9a-zA-Z]{6,}/)],
      ],
    });
    this.signinForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: [
        '',
        [Validators.required, Validators.pattern(/[0-9a-zA-Z]{6,}/)],
      ],
    });

    this.editUserForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: [
        '',
        [Validators.required, Validators.pattern(/[0-9a-zA-Z]{6,}/)],
      ],
    });
  }

  onSubmit() {
    const firstName = this.signupForm.get('firstName').value;
    const lastName = this.signupForm.get('lastName').value;
    const role = 'user';
    const phoneNumber = this.signupForm.get('phoneNumber').value;
    const email = this.signupForm.get('email').value;
    const password = this.signupForm.get('password').value;
    const user = new User(firstName, lastName, role, phoneNumber, email);
    this.userService.addUser(user);
    this.authService.createNewUser(email, password).then(
      () => {
        this.router.navigate(['/accueil']);
      },
      (error) => {
        console.log('error', error);
        this.errorMessage = error;
      }
    );
  }

  onEdit() {
    console.log('modification des informations');

    const firstName = this.editUserForm.get('firstName').value;
    const lastName = this.editUserForm.get('lastName').value;
    const role = 'user';
    const phoneNumber = this.editUserForm.get('phoneNumber').value;
    const email = this.editUserForm.get('email').value;
    const password = this.editUserForm.get('password').value;
    const user = new User(firstName, lastName, role, phoneNumber, email);
  }

  login() {
    const email = this.signinForm.get('email').value;
    const password = this.signinForm.get('password').value;
    this.authService
      .signInUser(email, password)
      .then(
        (user) => {
          console.log('user', user);
          this.router.navigate(['/acceuil']);
        },
        (error) => {
          this.errorMessage = error;
        }
      )
      .then(() => {
        this.userService.getUsers(email);
      });
  }

  logout() {
    this.authService.signOutUser();
  }

  addclass() {
    let container = document.getElementById('container');
    if (container.className === 'container') {
      container.classList.add('right-panel-active');
    } else {
      container.className = 'container';
    }
  }

  removeClass() {
    let container = document.getElementById('container');
    if (container.className === 'container') {
      container.classList.add('right-panel-active');
    }
  }
}
