export class Tuto {

  titre: string;
  body: string;

  constructor(titre: string, body: string) {
    this.titre = titre;
    this.body = body;
  }
}
