<section class="section-padding" id="about">
  <div class="container">
    <div class="row">
      <div class="col-md-12 text-center fadeIn" data-wow-duration="500ms" data-wow-delay="300ms">
        <h2 class="heading">Nous fournissons un service authentique, fiable et offrant des canaux IPTV Complets</h2>
      </div>
      <div class="col-md-4 col-sm-4 canvas-box magin30 text-center fadeInDown" data-wow-duration="500ms" data-wow-delay="300ms"> 
        <span class="text-center color1"><fa-icon [icon]="faHourglassHalf" size="3x"></fa-icon></span>
        <h4 class="color1">Test 24H </h4>
        <p>Test IPTV 24 Heures disponible sur demande.</p>
      </div>
	  <div class="col-md-4 col-sm-4 canvas-box magin30 text-center fadeInDown" data-wow-duration="500ms" data-wow-delay="500ms"> 
        <span class="text-center color2"><fa-icon [icon]="faCloudDownloadAlt" size="3x" ></fa-icon></span>
        <h4 class="color2">Sans Coupure</h4>
        <p>Notre INFRASTRUCTURE assure la sécurité des données AU PLUS HAUT niveau pour assurer un service sans coupure 24h/24.</p>
      </div>
	  <div class="col-md-4 col-sm-4 canvas-box magin30 text-center fadeInDown" data-wow-duration="500ms" data-wow-delay="300ms"> 
        <span class="text-center color3"><fa-icon [icon]="faTv" size="3x"></fa-icon></span>
        <h4 class="color3">Full HD - 4K </h4>
        <p>Nos serveurs IPTV disposent d’une playlist de plus de 1500 chaines en fullHD, HD et SD &amp; VOD en HD, FullHD, 4K.</p>
      </div>
      
      <div class="col-md-4 col-sm-4 canvas-box magin30 text-center fadeInDown" data-wow-duration="500ms" data-wow-delay="700ms"> 
        <span class="text-center color4"><fa-icon [icon]="faCog" size="3x"></fa-icon></span>
        <h4 class="color4">Configuration instantanée</h4>
        <p>Nous vous accompagnos pour l'installation<br> et la configuration après la commande.</p>
      </div>
      <div class="col-md-4 col-sm-4 canvas-box magin30 text-center fadeInDown" data-wow-duration="500ms" data-wow-delay="900ms"> 
        <span class="text-center color5"><fa-icon [icon]="faCreditCard" size="3x"></fa-icon></span>
        <h4 class="color5">Paiement</h4>
        <p> Paiement sécurisé en ligne <br>

      </div>
	  <div class="col-md-4 col-sm-4 canvas-box magin30 text-center fadeInDown" data-wow-duration="500ms" data-wow-delay="300ms"> 
        <span class="text-center color6"><fa-icon [icon]="faEye" size="3x"></fa-icon></span>
        <h4 class="color6">Zapping rapide</h4>
        <p>Chargement et Zapping des chaines très rapide.</p>
      </div>
	  
    </div>
  </div>
</section>