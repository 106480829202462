<div class="scroll-to-top" [ngClass]="{'show-scrollTop': true}">
    <button (click)="topFunction()">
        <span class="iconColor">
            <fa-icon [icon]="faArrowUp" size="2x"></fa-icon>
        </span> </button>
</div>

<div class="topnav" id="myTopnav">
    <div class="sub-menu">
        <a [ngClass]="{'current': current() === 'smartTV'}" routerLink="/tuto/smartTV" routerLinkActive="active"
            [fragment]="'smartTV'">
            SMARTTV
        </a>
        <a [ngClass]="{'current': current() === 'android'}" routerLink="/tuto/android" routerLinkActive="active"
            [fragment]="'android'">
            ANDROID
        </a>
        <a [ngClass]="{'current': current() === 'iphone'}" routerLink="/tuto/iphone" routerLinkActive="active"
            [fragment]="'iphone'">
            IPHONE
        </a>
        <a [ngClass]="{'current': current() === 'mag'}" routerLink="/tuto/mag" routerLinkActive="active"
            [fragment]="'mag'">
            MAG
        </a>
        <a [ngClass]="{'current': current() === 'vpn'}" routerLink="/tuto/vpn" routerLinkActive="active"
            [fragment]="'vpn'">
            VPN
        </a>
    </div>
</div>

<div class="custom-editor" *ngIf="isAuth">
    <form [formGroup]="editorForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <label for="editor" class="text-align">Editeur</label>
            <quill-editor [styles]="editorStyle" [modules]="editorModules"  formControlName="editorTemplate">
            </quill-editor>
        </div>
        <button class="btn btn-primary" type="submit">Save this Template</button>
    </form>
</div>
<div class="text-center" *ngFor="let tuto of tutos">
    <div [innerHTML]="tuto.body['editorTemplate']">
        <h1>{{tuto.titre}}</h1>

    </div>
    <button type="button" class="btn btn-primary" (click)="deleteTuto(tuto)">
        delete this tuto
    </button>
</div>
<div class="text-center">
    <h3>Comment utiliser le lien m3u ?</h3>
    <p>Lors de votre commande vous avez du recevoir un lien M3U, Si vous ne l'avez pas encore reçu, demandez le
        a
        votre
        contact, grace a ce lien vous allez pouvoir
        configurer n'importe quelle application IPTV.<br>
        Voici comment récupèrer vos identifiants dans le lien.
        comme suit:</p>
    <p>Le <strong>Username</strong> ,<strong>Password</strong> et le <strong>URL de server</strong> sont inclus
        dans votre lien m3u fournit lors de la commande ,</p>
    <p><strong>Exemple de lien m3u :</strong></p>
    <p><span style="color: #0000ff;"><strong>http://adresse-du-serveur.com:58901</strong></span>/get.php?<span
            style="color: #008000;"><strong>username=abcdef567</strong></span>&amp;<span
            style="color: #ff0000;"><strong>password=abCD123</strong></span>& type=m3u & output=ts</p>
    <p>Dans le cas de lien m3u ci-dessus, les détails de connexion au serveur sont les suivants :</p>
    <p><strong>Username :</strong> <span style="color: #008000;"><strong>abcdef567</strong></span></p>
    <p><strong>Password :</strong> <span style="color: #ff0000;"><strong>abCD123</strong></span><br>
        <strong>URL de serveur :</strong> <span
            style="color: #0000ff;"><strong>http://adresse-du-serveur.com:58901</strong></span></p>
</div>
<div class="text-center" id="vpn">
    <img src="../../assets/installer-vpn-smart-tv-800x445.jpg">
    <h4>VPN et Smart TV: le guide complet pour Samsung, LG…</h4>
    <p>Les Smart TV, essentiellement propulsées par Samsung et LG sont des téléviseurs
        connectés à internet
        qui permettent d’utiliser des applications diverses. Ces possibilités doivent absolument
        être sécurisées. Le VPN est le meilleur (et le seul..) moyen de protéger la connexion d’une Smart TV.
        Aussi, et surtout, les VPN Smart TV permettent d’accéder à plus de contenu sur les applications comme
        Netflix et Disney+.
        Seulement voilà, même les marques comme Samsung ne nous facilitent pas la tache dans l’installation d’un
        VPN sur leurs
        Smart TV.
        Voyons donc comment faire pour protéger nos téléviseurs.</p>
    <h4>Reconnaitre une Smart TV ou une Android TV</h4>
    <p>Sur le marché il existe deux types de téléviseurs connectés à internet : les Smart TV et les télés
        Android TV.
        La différence essentielle est le système d’exploitation. Et,
        bien évidemment, les modèles ne sont pas compatibles les uns avec les autres !
        Les Smart Tv sont principalement vendues par Samsung et LG. Si votre téléviseur est
        d’une de ces deux marques, vous pouvez donc être à peu prés certain d’avoir une Smart TV.
        Pour en être absolument sur, vérifiez le menu de votre télé. Il doit ressembler à l’image ci-dessous :
    </p>
    <img src="../../assets/Menu-Smart-TV.jpg" alt="Smart Tv">
    <p>Les Android TV sont elles vendues par les autres marques. Le système d’exploitation utilisé est une
        version modifiée du système android qui est sur les téléphones mobiles et les tablettes.
        C’est le même que sur les box android tv. Android TV ressemble donc à ceci :</p>
    <img src="../../assets/Menu-android-TV.jpg" alt="Android Tv">
    <p>
        Il est important de savoir si vous avez une Smart TV ou non afin de connaître la méthode d’installation
        du
        VPN. En effet, le tutoriel n’est pas du tout le même sur Android TV et sur Smart TV. Si vous avez une tv
        sous Android, félicitations, il vous « suffit » de télécharger l’application du VPN et de vous connecter.
        Mon
        tutoriel est le même que pour les box tv. (vidéo disponible dans mon guide d’achat de boitier iptv). Si
        vous
        avez une Smart TV, alors l’installation du VPN est un poil plus compliquée.
    </p>
</div>
<div class="text-center">
    <h4>configurer Set IPTV sur les téléviseurs Samsung
    </h4>
    <p>l’application à elle seule ne suffit pas à obtenir des chaines tv, il faut aussi avoir un abonnement IPTV
        avant de suivre ce tutoriel. Cette application remplace juste l’achat de la box android. Si vous n’avez
        pas d’abonnement, vous pouvez lire mon article sur comment avoir une abonnement iptv</p>
    <p>Votre télévision doit être connectée à internet.</p>
    <p>1. Tous d’abord installez SET IPTV sur votre SMART TV et cliquez sur Suivant.
    </p>
    <p>
        L’application est payante et coute 14.99€. Vous pouvez la tester pendant une semaine puis vous serez
        obliger de payer pour vous en servir. Voici comment l’activer.<br>
    </p>

    <img src="../../assets/Activation-smart-iptv-1.jpg">
    <p> 2. maintenant accédez a ce lien
        https://cms.manage-setiptv.com/set.app et choisissez Télécharger la
        playlist ou Upload playlist</p>
    <p>3. Entrez votre Macadress (que vous voyez dans l’application Set IPTV) et votre URL de liste de lecture
        externe.

    </p>
    <p>4. Appuyez sur envoyer et il sera sauvegardé.

    </p>
    <p>Maintenant c’est prêt.

    </p>
</div>

<div class="text-center" id="smartTV">
    <h3>Installation Smart IPTV sur une Smart TV
    </h3>
    <p>Votre télévision doit être connectée à internet.
    </p>
    <ul>
        <li>Allez dans le Smart Hub. Pour une tv Samsung, c’est la touche multicolore, sinon touche en forme de
            Maison)</li>
        <li>Allez ensuite dans l’app market. C’est l’endroit où vous pouvez télécharger plein d’applications
            pour votre tv.</li>
        <li>Vous devriez trouver l’application Smart IPTV dans les app les plus téléchargées.
            Cliquez sur Télécharger</li>
        <li>Patientez un peu puis cliquez sur Installation
        </li>
        <li>Vous pourrez enfin cliquer sur Exécuter ou Ouvrir
        </li>
    </ul>
    <p style="font-weight: bold;"><span>IMPORANT</span> L’application est payante et coute 5.49€ .
        Vous pouvez la tester gratuitement une semaine. Pour le paiement rendez vous dans la section ACTIVATION
        ci dessous </p>
    <img src="../../assets/smart-iptv-adresse-mac.jpg">
    <h3>Activation de l’application SMART IPTV
    </h3>

    <img src="../../assets/Activation-smart-iptv.png">
    <ul>
        <li>Lorsque vous démarrez l’application SMART IPTV, vous devriez voir une ligne « adresse MAC »,
            notez cette adresse (ou prenez une photo avec votre mobile)</li>
        <li>Allez sur le site https://siptv.eu/activation
        </li>
        <li>Saisissez une adresse mail
        </li>
        <li>Choisissez le mode de paiement Paypal ou CB (l’activation de l’application Smart IPTV sera
            immédiate)
        </li>
        <li>Validez le paiement et c’est tout.
        </li>
    </ul>
    <p>L’application Smart IPTV est liée à votre télévision. Si vous changez de tv, vous devrez donc payer
        l’application à nouveau.</p>
    <h3>Installation d’un abonnement IPTV sur SMART IPTV</h3>
    <p>Vous avez un abonnement IPTV à installer sur votre nouvelle application. C’est assez simple.
        voici comment faire pour mettre votre abo IPTV sur votre Smart TV:</p>
    <img src="../../assets/installation-iptv-smart-tv-e.jpg">
    <p>Vous devez avoir un fichier m3u ou, plus généralement, une adresse url vers un fichier playlist m3u du
        type (http://serveuriptv/blabla=m3u?user=xxxx?password=xxx..)
        Rendez vous sur le site http://siptv.eu/mylist/
        Renseignez l’adresse MAC de votre TV (celle qui a servie à activer Smart IPTV)
        Saisissez l’adresse de votre playslist dans la case URL (ou indiquer votre fichier m3u su vous avez un
        fichier)
        Cochez la case « je ne suis pas un robot »
        Cliquez sur Send
        Redémarrez votre tv, tout doit fonctionner</p> <br>
    <p>Important: Mon blog ne vend aucun abonnement. Si vous avez des soucis avec votre fournisseur, merci de
        voir avec lui directement (abo pas reçu, adresse mac erronée…)</p>
    <p>Autre Point Important: Pas mal de lecteurs se trompent et achètent cette application pensant pouvoir
        accéder à
        toutes les chaînes. Ce n’est pas le cas. Cette application permet juste d’installer un abonnement IPTV.
    </p>
</div>
<div class="text-center" id="android">
    <h3>Tuto : Configurer votre abonnement iptv sur l’application ipTV Smarters</h3>
    <img src="../../assets/iptv-smarters-pro-x96-mini.jpg">
    <p>L’application iptv Smarters Pro ou Player est l’une des meilleures applications de l’iptv, cet article
        explique comment installer et configurer iPTV Smarters Pro, et comment configurer les codes de votre
        abonnement ipTV android box sur iptv smarters,
        vous trouverez plus de détails concernant l’application iptv smarters pro (ou player pour les smart tv
        Samsung et LG)
        ci-dessous:</p>
    <h4>Comment installer l’abonnement iPTV sur iPTV Smarters Pro ?</h4>
    <ul>
        <ol>Rendez-vous sur le Play Store (Android), sur le Store
            de votre smart TV LG.</ol>
        <ol>Lancer la recherche en tapant iptv smarters pro (ou iptv smarters player pour les smart TV), vous
            serez sur l’écran de l’installation de l’application iptv Smarters pro, cliquer sur installer.</ol>
        <ol>A la fin de son installation, lancer l’application iptv smarters en cliquant sur Ouvrir.
        </ol>
        <ol>Attendre l’ouverture de l’application iPTV smarters.
        </ol>
        <ol>Cliquer sur connexion API Xtream-codes.
        </ol>
        <ol>Rentrez les identifiants de votre abonnement iptv : Nom, Utilisateur, Mot de passe et URL du
            Serveur (fourni par votre revendeur).</ol>
    </ul>
    <h3 style="text-align: left;"><strong>Liste des fonctionnalités de iPTV Smarters Pro:</strong></h3>
    <ul>
        <li>Utilisation facile et design intuitif</li>
        <li>Organisation de la playlist par chaines et vods, replay des chaines , EPG timeline ..</li>
        <li>Recherche de chaines et vods.</li>
        <li>2 lecteurs intégrés et possibilité d’utiliser VLC et MX Player comme lecteur externe.</li>
        <li>EPG (TV guide) et logos automatiques pour les chaines TV</li>
        <li>Informations sur Vods : Synopsis, Affiches des films et séries, notes ..</li>
        <li>Liste de favorites</li>
        <li>Informations de votre abonnement : Statut de l’abonnement, date d’expiration ..etc</li>
        <li>Fonctionne avec ou sans m3u (Grace à l’API Xtream-Codes)</li>
        <li><span style="color: #ff0000;"><strong>Nouveau! </strong><span style="color: #000000;">iPTV Smarters
                    Player est disponible pour les Smart TV Samsung et LG (Sur le store US).<br>
                </span></span></li>
    </ul>
    <img src="../../assets/iptv-smarters-pro.jpg">
    <h4><strong> IPTV smarters est disponible sur :</strong></h4>
    <ul>
        <li style="font-size: 2em;">Google Play Store et compatible avec : <strong>Box Android, Smartphones,
                tablettes, Android TV
                ..</strong></li>
    </ul>
    <h4 style="text-align: center;">Vous pouvez télécharger l’application <strong>iptv smarters pro</strong>
        pour android ici : <a
            href="https://goboutik.com/wp-content/uploads/IPTV Smarters Pro_v1.6.9_apkpure.com.apk"><strong><span
                    style="color: #3366ff;">iptv smarters pro</span></strong></a></h4>

    <blockquote>
        <p style="text-align: center;"><img loading="lazy" class="aligncenter size-full wp-image-25849"
                src="//i2.wp.com/goboutik.com/wp-content/uploads/2019/06/iptv-smarters-pro-x96-mini.jpg"
                alt="configurer iptv smarters pro" width="853" height="480"
                srcset="//i3.wp.com/goboutik.com/wp-content/uploads/2019/06/iptv-smarters-pro-x96-mini.jpg 853w, //i3.wp.com/goboutik.com/wp-content/uploads/2019/06/iptv-smarters-pro-x96-mini-500x281.jpg 500w, //i3.wp.com/goboutik.com/wp-content/uploads/2019/06/iptv-smarters-pro-x96-mini-300x169.jpg 300w, //i3.wp.com/goboutik.com/wp-content/uploads/2019/06/iptv-smarters-pro-x96-mini-768x432.jpg 768w"
                sizes="(max-width: 853px) 100vw, 853px"></p>
    </blockquote>
    <p>Pour éviter la saisie de tout votre lien m3u, nous vous suggérons de choisir l’option API Xtream-codes,
        cette application fonctionne aussi avec l’API Xtream-codes, avec identifiant, mot de passe et un URL de
        serveur.</p>

</div>
<div class="text-center" id="iphone">

</div>