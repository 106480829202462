import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-acceuil',
  templateUrl: './acceuil.component.html',
  styleUrls: ['./acceuil.component.scss'],
})
export class AcceuilComponent implements OnInit {
  public slides = [
    {
      src: './assets/messi_coupe.jpg',
    },
    {
      src: './assets/benzema.jpg',
    },
    {
      src: './assets/championsleague.jpg',
    },
    {
      src: './assets/apps.jpeg',
    },
  ];
  constructor() {}

  ngOnInit(): void {}
}
