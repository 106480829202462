import {Injectable} from '@angular/core';

@Injectable()
export class ModalService {
  constructor() {
  }

  open(id: string) {
    const modal = document.getElementById(id);
    if (!this.isOpen(id)) {
      document.body.classList.add('modal-open');
      modal.style.display = 'flex';
      const self = this;
      modal.addEventListener('click', (e: any) => {
        if (e.target.className === 'modal') {
          self.close(id);
        }
      });
    }
  }

  close(id: string) {
    const modal = document.getElementById(id);
    if (this.isOpen(id)) {
      document.body.classList.remove('modal-open');
      modal.style.display = 'none';
    }
  }

  isOpen(id: string) {
    const modal = document.getElementById(id);
    return document.body.classList.contains('modal-open') && modal.style.display === 'flex';
  }
}
