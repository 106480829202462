<footer class="footer-distributed">

    <div class="footer-left">

        <h3>NORD <span>IPTV</span></h3>
        <p style="color: white;">met à votre disposition un service IPTV,<br>
            puissant et stable avec <a href='#' class="link" routerLinkActive="active"
                routerLink="abonnement">différents forfaits,</a> <br>
            chaînes & VOD premium dans le monde entier.<br>
            On vous propose les prix les plus bas et la meilleure
            qualité.</p>

        <p class="footer-company-name">Nordigno &copy; 2020</p>
    </div>

    <div class="footer-center">

        <div>
            <i class="fa fa-map-marker"></i>
            <p><span>Lille</span> France</p>
        </div>

        <div class="snapshot">
            <i class="fa fa-snapchat " style="color: yellow;"></i>
            <p><a href="https://www.snapchat.com/add/nordigno59?share_id=QMIFh7Zyw_Q&locale=fr-FR">nordigno59</a></p>
        </div>

        <div class="snapshot">
            <i class="fa fa-telegram " style="color: white;"></i>
            <p><a href="https://t.me/NORDIPTV59">NORDIPTV59</a></p>
        </div>

        <div>
            <i class="fa fa-envelope"></i>
            <p><a href="mailto:Nordiptv5962@Gmail.Com">Nordiptv5962@Gmail.Com</a></p>
        </div>

    </div>

    <div class="footer-right">

        <p class="footer-company-about">
            <span>A Propos de nous</span>
            Fournisseur IPTV Européen. <fa-icon [icon]="faGlobeEurope" style="color: skyblue;" size="2x"></fa-icon>
        </p>

        <div class="footer-icons">

            <a href="#"><i class="fa fa-facebook" style="color: skyblue;"></i></a>
            <a href="#"><i class="fa fa-whatsapp" style="color:green;"></i></a>

        </div>

    </div>

</footer>