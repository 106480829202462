import {Injectable} from '@angular/core';
import {Md5} from 'ts-md5/dist/md5';

@Injectable()
export class CryptageService {


  encryptData(cuid) {
    const cuidHash = Md5.hashStr(cuid.toString());
    return cuidHash;
  }

}
