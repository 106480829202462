import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Faq } from '../models/faq';
import { FaqService } from '../services/faq.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Tuto } from '../models/tuto';
import { AuthService } from '../services/auth.service';
import * as firebase from 'firebase';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit, OnDestroy {
  isAuth: boolean;
  isUpdate: boolean = false;
  faqs: Faq[];
  faqForm: FormGroup;
  faqsSubscription: Subscription;
  constructor(private faqService: FaqService, private formBuilder: FormBuilder, private authService: AuthService) { }

  ngOnInit() {
    firebase.auth().onAuthStateChanged(
      (user) => {
        if (user) {
          this.isAuth = true;
        } else {
          this.isAuth = false;
        }
      }
    )
    this.initFaqForm();
    this.faqsSubscription = this.faqService.faqSubject.subscribe(
      (faqs: Faq[]) => {
        this.faqs = faqs;
      }
    );
    this.faqService.getFaqs;
    this.faqService.emitFaqs();
  }
  initFaqForm() {
    this.faqForm = this.formBuilder.group({
      id: 0,
      question: ['', Validators.required],
      reponse: ['', Validators.required],
    });
  }
  onSubmitFaqForm() {
    const formValue = this.faqForm.value;
    const newFaq = new Faq(
      this.faqs.length + 1,
      formValue['question'],
      formValue['reponse'],
    );
    this.faqService.createNewFaq(newFaq);
    this.initFaqForm();
  }

  onDeleteFaq(faq: Faq) {
    this.faqService.removeFaq(faq);
  }

  onEditFaq(faq: Faq, el: HTMLElement) {
    this.faqForm = this.formBuilder.group({
      id: faq.id,
      question: [faq.question, Validators.required],
      reponse: [faq.reponse, Validators.required],
    });
    el.scrollIntoView();
    this.isUpdate = true;
  }

  onUpdateFaq() {
    const formValue = this.faqForm.value;
    console.log('je suis dans onUpdate', formValue);
    const updatedFaq = new Faq(
      formValue['id'],
      formValue['question'],
      formValue['reponse'],
    );
    this.faqService.updateFaq(updatedFaq);
    this.initFaqForm();
  }


  ngOnDestroy() {
    this.faqsSubscription.unsubscribe();
  }
}
